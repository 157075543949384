import { unifiedAppParser } from 'api/parsers/unifiedAppParser';
import { fetch } from 'api/typedFetch';

import { approveAppByCountry } from 'helpers/approveAppByCountry';
import { localStorage } from 'helpers/localStorage';

export const getData = async () => {
  const user = localStorage.get('USER');

  if (!user) {
    throw new Error('User not found');
  }

  const response = await fetch('GET_APPS');
  const data = await response.json();

  if (data) {
    return data
      .map((app) => {
        return unifiedAppParser(app);
      })
      .filter((app) => {
        return approveAppByCountry(app, user.country);
      })
      .sort((a, b) => {
        return Number(b.id) - Number(a.id);
      });
  }

  throw new Error('Failed to fetch source apps');
};
